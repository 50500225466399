@import "var.scss";

.contentClass {
  padding: 0.3rem 0.8rem;
  z-index: 20;
  position: relative;
  background-color: var(--color-white);
  min-height: 82px;
  height: auto;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;

  > div {
    padding: 0;
  }
}

.menuStyles {
  max-width: var(--container-width);
  margin: 0 auto;
}

.stackStyles {
  position: relative;
  background-color: var(--color-themePrimary);
  color: var(--color-white);
  width: 100%;
  z-index: 19;
}
.stackStylesShadow {
  box-shadow: var(--box-shadow);
}

.itemStyle {
  color: var(--color-white);
  text-decoration: none;
  font-weight: bold;

  >span {
    position: unset!important;
  }

  &:global(.active) {
    color: var(--color-greenSection)!important;
  }
}
.menu-enter {
  opacity: 0;
  transform: translateY(-100px);
}
.menu-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms, height 300ms;
}
.menu-exit {
  opacity: 1;
}
.menu-exit-active {
  opacity: 0;
  transform: translateY(-100px);
  transition: opacity 300ms, transform 300ms, height 300ms;
}

.poweredBy {
  color: var(--color-themePrimary);
  font-weight: bold;
  display: flex;
  align-items: center;

  span {
    position: unset;
    padding: unset!important;
    padding-top:5px!important;
  }
}

.OpenMenuEnter {
  opacity: 0;
  transform: rotate(0deg);
}
.OpenMenuEnterActive {
  opacity: 1; 
  transform: rotate(180deg);
  transition: all .3s ease;
}
.OpenMenuExit {
  opacity: 1;
  transform: rotate(180deg);
}
.OpenMenuExitActive {
  opacity: 0;
  transform: rotate(0deg);
  transition: all .3s ease;
}

.iconPosition {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.burgerMenu {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: white;
  border: unset;
  top: 0;
  right: 20px;
  z-index: 29;
  transform: translateY(50%);
}

.HeaderLogo {
  color: var(--color-themePrimary);
  font-size: 1rem;
  display: flex;
}

.LogoInMenu {
  color: var(--color-themePrimary);
  font-size: 1rem;
  display: flex;
}

.isMobileMenu {
  flex-direction: column;
}

.ios-download {
  position: fixed;
  bottom: 0;
  right: 0;
}

@media screen and (min-width: $up-media-mobile) {
  .poweredBy {
    font-size: .8rem;
  }
  .itemStyle {
    font-size: .7rem;
    font-weight: normal;
  }
  #HeaderLogo {
    margin-top: 5px;
  } 
}
