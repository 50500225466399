.closeButton {
  position: absolute;
  top: 0;
  right: 0;
}

.headerTitle {
  line-height: 32px;
}

.modalStyles {
  border: 1px solid var(--color-themePrimary);
  overflow: hidden;
}

.modalBody {
  padding: 10px 2rem;
  max-width: 600px;
  .container-lg {
    padding-left: 0;
    padding-right: 0;
  }
}