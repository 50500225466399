$themePrimaryLight: #C4DA5E;
$themePrimary: #366d4f;
$themeLighterAlt: #f4f9f6;
$themeLighter: #d5e8de;
$themeLight: #b4d3c2;
$themeTertiary: #75a88c;
$themeSecondary: #477f60;
$themeDarkAlt: #316348;
$themeDark: #2a533c;
$themeDarker: #1f3d2d;
$neutralLighterAlt: #faf9f8;
$neutralLighter: #f3f2f1;
$neutralLight: #edebe9;
$neutralQuaternaryAlt: #e1dfdd;
$neutralQuaternary: #d0d0d0;
$neutralTertiaryAlt: #c8c6c4;
$neutralTertiary: #c6c6c6;
$neutralSecondary: #8c8c8c;
$neutralPrimaryAlt: #575757;
$neutralPrimary: #404040;
$neutralDark: #303030;
$black: #242424;
$white: #ffffff;


/* Basic Meida Queries */
$up-media-large-screen: 1560px;
$media-large-screen: 1559px;
$up-media-desktop: 1400px;
$media-desktop: 1399px;
$up-media-laptop: 1200px;
$media-laptop: 1199px;
$up-media-tablet: 992px;
$media-tablet: 991px;
$up-media-mobile: 768px;
$media-mobile: 767px;
$up-media-samll-mobile: 576px;
$media-samll-mobile: 575px;
$media-end: 320px;

