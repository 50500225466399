.spinnerOverlay {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  z-index: 18;
  height: 200px;
  transform: translate(-50%, -50%);
  border-radius: 0;

  > span {background-position: center center!important;position: absolute!important;left: 0;top: 0;width: 100%!important;height: 100%!important;}

  > span > span {display: none!important;}

  > span img {
    min-width: unset!important;
    min-height: unset!important;
    width: 60px!important;
    height: 38px!important;
    transform: translateX(0px) translateY(-30px)!important;
  }
}

.fullSize {
  height: 100%;
}

.fadeIn200Enter {
  opacity: 0;
}
.fadeIn200EnterActive {
  opacity: 1;
  transition: opacity 0.2s;
}
.fadeIn200Exit {
  opacity: 1;
}
.fadeIn200ExitActive {
  opacity: 0;
  transition: opacity 0.2s;
}

.bearLoading {
  width: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: -25px;
}