.callout {
  width: 320px;
  padding: 20px 24px;
}

.title {
  margin-bottom: 12px;
  font-weight: semilight;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
};