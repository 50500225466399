@import 'var.scss';

:root {
  --container-width: 1920px;
  --color-themePrimaryLight: #C4DA5E;
  --color-themePrimary: #366d4f;
  --color-themeLighterAlt: #f4f9f6;
  --color-themeLighter: #d5e8de;
  --color-themeLight: #b4d3c2;
  --color-themeTertiary: #75a88c;
  --color-themeSecondary: #477f60;
  --color-themeDarkAlt: #316348;
  --color-themeDark: #2a533c;
  --color-themeDarker: #1f3d2d;
  --color-neutralLighterAlt: #faf9f8;
  --color-neutralLighter: #f3f2f1;
  --color-neutralLight: #edebe9;
  --color-neutralQuaternaryAlt: #e1dfdd;
  --color-neutralQuaternary: #d0d0d0;
  --color-neutralTertiaryAlt: #c8c6c4;
  --color-neutralTertiary: #c6c6c6;
  --color-neutralSecondary: #8c8c8c;
  --color-neutralPrimaryAlt: #575757;
  --color-neutralPrimary: #404040;
  --color-neutralDark: #303030;
  --color-black: #242424;
  --color-white: #ffffff;
  --color-greenSection: #C4DA5E;
  --color-orange: #D7792D;
  --color-blue: #4f81bd;
  --color-green: #a8c151;
  --color-red: #CB4127;
  --color-lightBlueSection: #64CADE;
  --color-lightOrangeSection: #FFD365;
  --color-lightYellowSection: #F8F6E3;
  --border-radius: 10px;
  --box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}

@import url("https://use.typekit.net/epp2sor.css");

html,
body,
#__next,
#__next>div {
  min-height: 100%;
  scroll-behavior: smooth;
}

a {
  color: var(--color-orange);
  text-decoration: none;
  cursor: pointer;
}

#__next>div {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// Typography
html {
  & {
    font-size: 24px;
  }

  @media screen and (max-width: $media-desktop) {
    font-size: 24px;
  }

  @media screen and (max-width: $media-laptop) {
    font-size: 24px;
  }

  @media screen and (max-width: $media-tablet) {
    font-size: 22px;
  }

  @media screen and (max-width: $media-mobile) {
    font-size: 20px;
  }

  @media screen and (max-width: $media-samll-mobile) {
    font-size: 18px;
  }

  @media screen and (max-width: 360px) {}
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  line-height: 1.5;
}

body * {
  margin: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  font-weight: normal;
}

h1:not([class^="h"]),
h2:not([class^="h"]) {
  font-size: 2rem;
}

h1.display-1,
h2.display-2 {
  font-size: 3rem;
}

h1:not(:only-child):not(:last-child),
h2:not(:only-child):not(:last-child) {
  margin-bottom: .8rem;
}

h3,
.h3 {
  font-size: 2rem;
}

h4,
.h4 {
  font-size: 1.3rem;
}

h5,
.h5 {
  font-size: 1.2rem;
}

h6,
.h6 {
  font-size: 1.1rem;
}

.h7 {
  font-size: 1rem;
}

h1.display,
h2.display {
  font-size: 3.2rem;
  line-height: 1;
}

p:not(:last-child) {
  margin-bottom: .8rem;
}

// End Typography
.text-center {
  text-align: center;
}

// Button and input styles
.btn {
  font-size: 1rem;
  padding: .6rem .8rem;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 600;
  max-width: 8rem;
}

.btn-100 {
  max-width: 100%;
  width: 100%;
}

@keyframes changewidth {
  from {
    outline-offset: 0px;
    outline: 3px solid;
    outline-color: rgb(196 218 94 / 100%);
  }

  to {
    outline-offset: 15px;
    outline: 1px solid;
    outline-color: rgb(196 218 94 / 0%);
  }
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 300% 0; }
    100% { background-position: 0 0; }
}

.sticky-calc {
  &.sticky {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: auto;
    font-size: .6em;
    z-index: 10;
    background: #366d4f;
    animation-duration: 1500ms;
    animation-name: changewidth;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

    &:hover {
      animation: none;
      outline: 0px;

      &:before {
        opacity: 1;
      }
    }

    &:before {
      content: ' ';
      background: linear-gradient(45deg, #ff0000, #ff7300, #7a00ff, #ff00c8, #ff0000);
      position: absolute;
      top: -2px;
      left:-2px;
      background-size: 300%;
      z-index: -2;
      filter: blur(10px);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      animation: glowing 20s linear infinite;
      opacity: 0;
      transition: opacity .3s ease-in-out;
      border: unset;
      border-radius: inherit;
    }

    &:after {
      z-index: -2;
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      background: #366d4f;
      border: 2px solid #366d4f;
      left: -2px;
      top: -2px;
      border-radius: inherit;
    }
  }
}

input {
  font-size: 1rem;
  padding: .6rem 0;
}

label {
  color: #fff;
}

input {
  color: $themePrimaryLight;
  background: transparent;
  border: 2px solid transparent;
  border-bottom-color: $themePrimaryLight;
  width: 100%;
  max-width: 100%;
}

input:hover,
input:focus {
  border: 2px solid transparent;
  border-bottom-color: $themePrimaryLight;
  outline: 0;
}

::-webkit-input-placeholder {
  color: $themePrimaryLight;
}

:-ms-input-placeholder {
  color: $themePrimaryLight;
}

::placeholder {
  color: $themePrimaryLight;
}

@media screen and (min-width: $up-media-mobile) {
  .btn {
    font-size: .8rem;
    padding: 0.4rem 0.6rem;
  }

  input {
    font-size: .8rem;
    padding: 0.4rem 0;
  }
}

.btnThemePrimary {
  color: $themePrimaryLight!important;
  background-color: $themePrimary;
  border-color: $themePrimary;
}

.btnThemePrimary:hover,
.btnThemePrimary:focus {
  background-color: $themeDark;
  border-color: $themeDark;
}

.btnThemePrimaryLight {
  color: $themePrimary;
  background-color: $themePrimaryLight;
  border-color: $themePrimaryLight;
}

.btnThemePrimaryLight:hover,
.btnThemePrimaryLight:focus {
  background-color: lighten($themePrimaryLight, 10%);
  border-color: lighten($themePrimaryLight, 10%);
}

.btnLink {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}

.btnLink:hover,
.btnLink:focus {
  color: $themePrimaryLight;
  background-color: transparent;
  border-color: transparent;
}

// General styles
//Basic grid
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--theme-gutter-x, 0.75rem);
  padding-left: var(--theme-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: $up-media-samll-mobile) {

  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: $up-media-mobile) {

  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: $up-media-tablet) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: $up-media-laptop) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: $up-media-desktop) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

@media (max-width: $media-mobile) {
  .container-lg {
    padding: 0;
  }
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  --theme-gutter-x: .8rem;
  --theme-gutter-y: 0;
  margin-top: calc(var(--theme-gutter-y) * -1);
  margin-right: calc(var(--theme-gutter-x) * -.5);
  margin-left: calc(var(--theme-gutter-x) * -.5);
}

.row>*,
.row>.ms-Stack-inner>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--theme-gutter-x) * .5);
  padding-left: calc(var(--theme-gutter-x) * .5);
  margin-top: var(--theme-gutter-y);
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

//End Basic grid
img {
  max-width: 100%;
}

hr {
  border-color: var(--color-themePrimary);
  box-shadow: unset;
  border-bottom: 0;
  width: 80%;
  text-align: left;
  margin-bottom: .8rem;
}

ul {
  padding-left: 1rem;
}

.thin {
  font-weight: 200;
}

.regular {
  font-weight: 400;
}

.bold {
  font-weight: 600;
}

.bolder {
  font-weight: 700;
}

.boldest {
  font-weight: 800;
}

// Grid 
.padding-section {
  padding: 2rem;
}

.responsive-col {
  width: 100%;
  max-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.responsive-background {
  height: auto;
}

@media screen and (min-width: $up-media-tablet) {
  .responsive-col {
    width: 50%;
    max-width: 50%;
  }

  .order-lg-2 {
    order: 2;
  }
}

.nonScrollable {
  overflow: hidden;
  margin-right: 5px;
}

#__next {
  width: 100%;
  max-width: var(--container-width);
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.12);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d9d9d9;
  position: absolute;
  margin-top: 10px;
  width: 8px;
  margin-bottom: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-themePrimary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-themeSecondary);
}
.withOverlay{
  &:after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    z-index: 1;
    background-color: rgba(0, 0, 0, .5);
  }
  >*{
    position: relative;
    z-index: 2;
  }

  h1{
    text-shadow: 1px 1px 6px #000;
  }
}
.is-news {
  .hero-section {
    padding: 4rem;
  }
}

.site-content > * {
  width: 100%;
  margin-left: 0!important;
}

// Extract this
.hero-section {
  display: block;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  min-height: 72vh;
  width: 100%;

  &.hero-brokering {
    display: flex;
    align-items: center;

    &:first-child:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      z-index: 0;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 50%, rgba(255, 255, 255, 0) 60%);
    }
  
    .hero-body {
      width: calc(100% - 8rem);
      position: relative;
      bottom: unset;
      z-index: 2;
    }
  }

  .slick-slider {
    min-height: 100vh;

    &.slick-initialized {
      display: flex;

      .slick-slide {
        display: flex;

        &>div {
          display: flex;
          width: 100%;

          &>div {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .slick-list,
    .slick-track {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }

    .slick-list {
      height: auto !important;
      width: 100%;
    }

    img {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center left;
    }
  }

}

@media screen and (min-width: $up-media-tablet) {
  .hero-section {
    &.hero-brokering {
      .hero-body {
        text-align: right;

        .title {
          font-size: 3rem;
          text-shadow: 1px 1px 6px #000;
        }
      }
    }
  }
}

@media screen and (max-width: $media-mobile) {
  .hero-section {
    .slick-slider {
      img {
        &.Slide-0 {
          object-position: 15% 50%;
        }
      }
    }
  }
}

.glassMorph {
  background: rgba(0, 0, 0, 0.45);
  box-shadow: 0 3px 10px 0 rgba(31, 38, 135, 0.37);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.20);
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .glassMorph {
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(5.0px);
    -webkit-backdrop-filter: blur(5.0px);
    box-shadow: 0 3px 10px 0 rgba(31, 38, 135, 0.37);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.20);
  }
}

.hero-body {
  position: absolute;
  bottom: 3rem;
  left: 0;
  right: 0;
  text-align: center;
  width: calc(100% - 4rem);
  min-height: 15vh;
  margin: 0 auto;
  max-width: 1200px;
}

.hero-content {
  padding: 1rem;
  color: var(--color-white);
  backdrop-filter: blur(10.0px);
  -webkit-backdrop-filter: blur(10.0px);
}

.btnConatiner {
  background-color: var(--color-themeDarker);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  height: 350px;
  display: flex;
  z-index: 40;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;

  a {
    font-size: 16px;
    color: var(--color-themePrimaryLight);
    font-weight: 600;
    height: 175px;
    margin: 0;
    padding: 0;
    text-align: center;
    text-decoration: none;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-180deg);
    line-height: 1.2;
    /* -webkit-transform-origin: left; */
    /* transform-origin: left; */
    width: 66px;
    writing-mode: vertical-rl;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    &:nth-child(1) {
      color: white;
      background-color: var(--color-orange);
      border-bottom-left-radius: 12px;
    }
  }

  div:last-child:after {
    content: "";
    border-top: 1px solid var(--color-themePrimaryLight);
    padding-top: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    transform: translateY(-2px);
  }

}

// Until here
// Calculator form
.calcSection,
.contactUsSection,
.loginSection {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.contactUsSection {
  position: relative;

  input {
    color: $themePrimary;
    background: transparent;
    border: 2px solid transparent;
    border-bottom-color: $themePrimary;
  }

  textarea {
    color: $themePrimary;
    font-size: 1.2em;
    width: 100%;
    min-height: 6em;
    background: transparent;
    border: 2px solid transparent;
    border-bottom-color: $themePrimary;
  }

  textarea:focus {
    outline: unset;
  }

  button {
    margin-top: 2em;
  }
}

.contactRow {
  align-items: flex-start;

  h2 {
    color: $themePrimary;
  }

  .result {
    color: $themeDarker;
    font-weight: 600;
  }

  p,
  label {
    line-height: 1.2;
    color: $themeDarker;
  }

  label {
    font-size: 0.7em;
  }

  input,
  textarea {
    color: $themeDarker;
  }

  input::placeholder,
  textarea::placeholder {
    color: $themeSecondary;
  }

}

.calcRow {
  align-items: flex-start;

  h2 {
    color: $themeDarker;
  }

  .result {
    color: #fff;
    font-weight: 600;
  }

  p,
  label {
    line-height: 1.2;
    color: #fff;
  }
}

.modalStyles{
  &.calc {
    background-color: var(--color-themePrimary);
  
    .modalBody {
      padding: 1rem 0;
  
      .calcRow {
        .col {
          flex: 0 0 auto;
          width: 100%;
        }
      }
    }
  
    .closeButton {
      padding: 1rem;
  
      &:hover {
        background-color: transparent;
      }
  
      .ms-Button-flexContainer {
        color: #fff;
  
        &:hover {
          color: var(--color-themePrimaryLight);
        }
      }
    }
  }
}

@media screen and (min-width: $up-media-mobile) {
  .calcRow {

    p,
    label {
      font-size: .8rem;
      font-weight: normal;
    }

    button {
      margin-top: 1.5rem;
    }
  }

  .loginRow {

    p,
    label {
      font-size: .8rem;
    }

    button {
      margin-top: 1.5rem;
    }
  }
}

@media screen and (max-width: $media-mobile) {
  .calcRow {
    .col {
      flex: 0 0 auto;
      width: 100%;
    }

    button {
      margin-top: 1rem;
      width: 100%;
      max-width: unset;
    }
  }

  .loginRow {
    .col {
      flex: 0 0 auto;
      width: 100%;
    }

    button {
      margin-top: 1rem;
      width: 100%;
      max-width: unset;
    }
  }

  .contactRow {
    .col {
      flex: 0 0 auto;
      width: 100%;
    }

    button {
      margin-top: 1rem;
      width: 100%;
      max-width: unset;
    }
  }
}

.modalStyles.login {
  background-color: var(--color-white);

  .modalBody {
    padding: 1rem 0;

    .calcRow {
      .col {
        flex: 0 0 auto;
        width: 100%;
      }
    }
  }

  .closeButton {
    .ms-Button-flexContainer {
      color: var(--color-neutralPrimary);

    }
  }

}

.closeButton {
  padding: .7rem;
}

.loginRow {
  align-items: flex-start;

  h2 {
    color: $themeDarker;
  }

  .result {
    color: $themePrimary;
    font-weight: 600;
  }

  p,
  label {
    line-height: 1.2;
    color: $themePrimary;
  }
}

.funnelIcons {
  cursor: pointer;
  width: 140px;
  height: 140px;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  .circle {
    background-color: white;
    border-radius: 50%;
    top: 0;
    width: 100%;
    max-width: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.20);

    > span {
      position:absolute!important;
      width: 100%!important;
      height: 100%!important;
      transform: translateY(-50%)!important;
    }
  }

  img {
    position: absolute!important;
    top: 0!important;
    bottom: 0!important;
    margin: auto!important;
    left: 50%!important;
    width: 60px!important;
    inset: unset!important;
  }
}

@media screen and (max-width: $media-laptop) {
  .funnelIcons {
    bottom: 25px;
  }
}

.two-columns {
  column-count: 2;
  max-width: 1000px;
  padding-left: 1rem;
}

@media screen and (min-width: $up-media-laptop) {
  .two-columns {
    font-size: .8rem;
  }
}

@media screen and (max-width: $media-samll-mobile) {
  .two-columns {
    column-count: 1;
  }
}

.borderBox {
  border: 10px solid var(--color-orange);
  border-radius: 1.8rem;
  padding: 2rem;
  position: relative;

  ul {
    column-count: 2;
    max-width: 1000px;
  }
}

.borderBox h2 {
  position: absolute;
  transform: translateY(-70%);
  background-color: white;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 80%;
  text-align: center;
  margin: auto;
  padding: 0 1rem;
  line-height: 1;
}

.myCTA,
.myCTAReverse {
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 1em;

}

.myCTA {
  top: 0;
  font-size: 1.2rem;
  background-color: var(--color-orange);
  padding: 1.5rem 1rem 1rem 1rem;
  transform: translateY(-56%);
}

.myCTAReverse {
  transform: translateY(50%);
  background-color: var(--color-themePrimary);
  bottom: 0;
  font-size: 3.2rem;
  padding: 2rem 1.5rem 1.5rem 1.5rem;
}

.splitSection {
  padding: 5rem 0 0 0;
}

.splitSectionGreen {
  background-color: var(--color-greenSection);
}

// Footer
.footer-container {
  background-color: var(--color-lightYellowSection);
  padding: 1rem 2rem;
  margin-top: auto;

  a {
    text-decoration: none;
    color: black;
  }

  .row {
    > div {
      display: flex;
      flex-flow: row wrap;
      margin: 0px;
      overflow: visible;
      box-sizing: border-box;
      width: 100%;
      max-width: 100vw;
      align-items: center;
      height: 100%;
    }
  }
}

.col.responsive-footer-col {
  flex: 0 0 auto;
  width: 25%;

  &:first-child {
    width: 17%;
  }
}

.footer-logo {
  align-self: center;
  padding: 1rem 0;
  width: auto;

  a {
    display: inline-block;
  }
}

.quick-links {
  list-style: none;
  padding-left: 0;

  li {
    margin: .1rem 0;
    padding-right: 1rem;
  }

  li {
    float: left;
    width: 100%;
  }

  a {
    float: left;
    width: 100%;
    font-weight: bold;
  }
}

.copyright {
  text-align: center;
  width: 100%;
}

@media screen and (min-width: $up-media-mobile) {
  .footer-logo {
    padding: 1rem;

    a {
      border-right: 1px solid #000;
      padding-right: 1rem;
    }
  }

  .footer-container {
    a {
      font-size: .8rem;
    }
  }
}

@media screen and (max-width: $media-mobile) {
  .col.responsive-footer-col {
    flex: 0 0 auto;
    width: 100%!important;
    margin-left: 0!important;
  }

  .quick-links {
    li {
      margin: .2rem 0;
    }
  }

  .btn.btnThemePrimary,
  .copyright {
    margin-top: 1rem;
  }
}

.privacypolicy,
.detailed-requirements,
.full-details {
  max-width: 30rem;
  padding: 2rem 1rem;
  overflow: hidden;
  display: flex;
}

.privacypolicy,
.detailed-requirements,
.full-details {
  .modalBody {
    max-width: unset;
    margin-bottom: 5rem;
    padding: 10px 1rem;

    h1 {
      font-size: 1.3rem;
    }

    h2 {
      font-size: 1.15rem;
    }

    h3 {
      font-size: 1.05rem;
    }

    p,
    li {
      font-size: 0.8rem;
    }
  }
}

.ms-Modal {
  .glassMorph {
    border-radius: 0;
  }
}

.informedSection {
  padding: 2rem 0rem;
}

label {
  label {
    color: var(--color-themePrimary) !important
  }
}

.mt-10 {
  margin-top: 10px;
}

.mt-30 {
  margin-top: 30px;
}

.p-10 {
  padding: 10px;
}

.col.p-10 p {
  margin-bottom: 0;
}

.col.p-10 p:last-child {
  margin-top: 0.5rem;
}

.col.p-10 p span {
  color: var(--color-greenSection);
}

:global(.slick-dots) {
  bottom: 0px;

  li {
    button {
      background: white;
      border-radius: 50%;
      width: 16px;
      height: 16px;

      &:before {
        color: white;
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
    }
  }
}

.co_paragraph {
  margin-bottom: 0.6rem;
}

#HeaderLogo {

  span {
    position: unset;
    padding: unset!important;
    padding-top:5px!important;
  }

  .logoSize {
    width: 100%!important;
    height: auto !important;
    max-width: 170px!important;
    max-height: 50px!important;
    min-height: 40px!important;
    position: unset!important;
    inset: unset!important;
    box-sizing: unset!important;
    padding: unset!important;
    border: unset!important;
    margin: unset!important;
    display: unset!important;
    object-fit: unset!important;
  }
}

#LogoInMenu {
  color: var(--color-themePrimary);
  font-size: 1rem;
  display: flex;

  span {
    position: unset;
    padding: unset!important;
    padding-top:2px!important;
  }

  .logoSize {
    width: auto!important;
    height: 100%!important;
    max-height: 30px!important;
    min-height: 30px!important;
    position: unset!important;
    inset: unset!important;
    box-sizing: unset!important;
    padding: unset!important;
    border: unset!important;
    margin: unset!important;
    display: unset!important;
    object-fit: unset!important;
  }
}

.agrominLogo {
  max-height: 40px!important;
  width: auto!important;
  height: unset!important;
  min-height: 30px!important;
  max-width: 100%!important;
  min-width: unset!important;
  position: unset!important;
  inset: unset!important;
  box-sizing: unset!important;
  padding: unset!important;
  border: unset!important;
  margin: unset!important;
  margin-left: 1rem!important;
  display: unset!important;
  object-fit: unset!important;
}

.max-height-60 {
  max-height: 60px!important;
}

.max-height-100 {
  max-height: 100px!important;
}

.max-height-200 {
  max-height: 200px!important;
}

@media screen and (max-width: $up-media-tablet) {
  .orSeparator {
    bottom: 0px;
    top: unset!important;
    right: unset!important;
    left: 50%;
    transform: translateX(-50%) translateY(50%)!important;
  }
}

.orSeparator {
  position: absolute;
  top: 20px;
  right: 0;
  background-color: $white;
  transform: translateX(50%);
  z-index: 10;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  display: flex;
  padding-top: 5px;
  justify-content: center;
  align-items: center;
  color: var(--color-orange);
  font-size: 1.8em;
  letter-spacing: 0px;
}

@media screen and (min-width: $up-media-laptop) {
  .long-section{
    margin-bottom: 100px;
  }
}

.absolute-position-image {
  position: absolute;
  right: 0
}



.brokering-solution-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  &.sectionPaddings {
    padding: 2rem;
  }

  h2.h3 {
    color: #cc7e4d;
    max-width: 1070px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}


.arrow-overlap {
  background-color: #C4DA5E;
  position: relative;
  z-index: 2;
  padding: 1rem 1.8rem 0;
  .overlap-content{
    h2 {
      text-align: center;
      color: white;
    }
  
    p {
      text-align: center;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
svg.svg-triangle {
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  margin-top: -1px;
  z-index: 2;
}

@media screen and (min-width: $up-media-tablet) and (max-width: $media-laptop) {
  .brokering-solution-section {
    .bs-center-responsive-col {
      flex: 0 0 40% !important;
      max-width: 40% !important;
    }
  }

  .bs-borderBox {
    min-height: 10rem;
  }
}

@media screen and (min-width: $up-media-tablet) {
  .brokering-solution-section {
    &.sectionPaddings {
      padding: 2rem 6rem;
    }

    .bs-left-responsive-col,
    .bs-right-responsive-col {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 30%;
    }

    .bs-left-responsive-col {
      padding-right: 1rem;

      .bs-borderBox:before {
        content: " ";
        position: absolute;
        right: calc(-1rem - 6px);
        top: 50%;
        transform: translateY(-50%);
        border-top: 1rem solid transparent;
        border-right: none;
        border-left: 1rem solid var(--color-blue);
        border-bottom: 1rem solid transparent;
      }
    }

    .bs-right-responsive-col {
      padding-left: 1rem;

      .bs-borderBox:before {
        content: " ";
        position: absolute;
        left: calc(-1rem - 6px);
        top: 50%;
        transform: translateY(-50%);
        border-top: 1rem solid transparent;
        border-right: 1rem solid var(--color-blue);
        border-left: none;
        border-bottom: 1rem solid transparent;
      }
    }

    .bs-center-responsive-col {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 1rem;
      padding-right: 1rem;

      .bs-borderBox:before {
        content: " ";
        position: absolute;
        left: calc(-1rem - 6px);
        top: 50%;
        transform: translateY(-50%);
        border-top: 1rem solid transparent;
        border-right: 1rem solid var(--color-green);
        border-left: none;
        border-bottom: 1rem solid transparent;
      }

      .bs-borderBox:after {
        content: " ";
        position: absolute;
        right: calc(-1rem - 6px);
        top: 50%;
        transform: translateY(-50%);
        border-top: 1rem solid transparent;
        border-right: none;
        border-left: 1rem solid var(--color-green);
        border-bottom: 1rem solid transparent;
      }

      .arrow-holder {
        max-width: 60%;
        font-size: .8rem;

        svg.svg-triangle-left {
          display: block;
          top: 0;
          left: 0;
          transform: translate(-132%, -38%) scaleX(-1);
        }

        svg.svg-triangle-right {
          display: block;
          top: 0;
          right: 0;
          transform: translate(132%, -38%);
        }
      }
    }

    ul {
      font-size: .8rem;
      color: #5b80b9;
      line-height: 1.2;
      margin-top: .5rem;

    }
  }
}

@media screen and (min-width: $up-media-large-screen) {
  .brokering-solution-section {
    .bs-borderBox {
      min-height: 9rem;
    }

    .bs-center-responsive-col {
      .arrow-holder {
        max-width: 50%;
        font-size: 1rem;

        svg.svg-triangle-left {
          display: block;
          top: 0;
          left: 0;
          transform: translate(-165%, -38%) scaleX(-1);
        }

        svg.svg-triangle-right {
          display: block;
          top: 0;
          right: 0;
          transform: translate(165%, -38%);
        }
      }
    }

    ul {
      font-size: .9rem;
    }
  }
}

@media screen and (max-width: $media-tablet) {
  .brokering-solution-section {
    .bs-col:last-child {
      margin-top: 1rem;
    }

    .bs-left-responsive-col,
    .bs-right-responsive-col {
      all: unset;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .bs-borderBox {
      margin: 1rem 0;
    }

    .bs-left-responsive-col {
      .bs-borderBox:before {
        content: " ";
        position: absolute;
        left: 50%;
        bottom: calc(-1rem - 6px);
        transform: translateX(-50%);
        border-top: 1rem solid var(--color-blue);
        border-right: 1rem solid transparent;
        border-left: 1rem solid transparent;
        border-bottom: none;
      }
    }

    .bs-right-responsive-col {
      .bs-borderBox:before {
        content: " ";
        position: absolute;
        left: 50%;
        top: calc(-1rem - 6px);
        transform: translateX(-50%);
        border-top: none;
        border-right: 1rem solid transparent;
        border-left: 1rem solid transparent;
        border-bottom: 1rem solid var(--color-blue);
      }
    }

    .bs-center-responsive-col {
      .bs-borderBox:before {
        content: " ";
        position: absolute;
        left: 50%;
        top: calc(-1rem - 6px);
        transform: translateX(-50%);
        border-top: none;
        border-right: 1rem solid transparent;
        border-left: 1rem solid transparent;
        border-bottom: 1rem solid var(--color-green);
      }

      .bs-borderBox:after {
        content: " ";
        position: absolute;
        left: 50%;
        bottom: calc(-1rem - 6px);
        transform: translateX(-50%);
        border-top: 1rem solid var(--color-green);
        border-right: 1rem solid transparent;
        border-left: 1rem solid transparent;
        border-bottom: none;
      }
    }

    ul {
      max-width: 80%;
      margin: 1rem auto 0;
    }
  }

}

.bs-borderBox {
  border: 6px solid var(--color-blue);
  border-radius: 1.8rem;
  padding: .8em;
  position: relative;
  text-align: center;
  min-height: 8rem;
  display: flex;
  justify-content: center;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.bs-center-responsive-col {
  text-align: center;

  .bs-borderBox-center {
    border: 6px solid var(--color-green);

    ul {
      padding-left: 0;

      li {
        display: inline;
        color: var(--color-black);
      }
    }
  }

  .arrow-holder {
    max-width: 80%;
    color: #616562;
    line-height: 1.2;
    margin-top: .5rem;
    text-align: center;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    svg.svg-triangle-left,
    svg.svg-triangle-right {
      display: none;
      position: absolute;
      z-index: 2;
      max-width: 40%;
    }
  }
}

[class*="activity-"] {
  margin-top: 3px;
}

.restricted-content {
  max-width: 400px;
  background-color: #fafafa;
  padding: 30px;
  margin: 30px auto;
}

.right-side-bar {
  > div { 
    > div:first-child {
      max-width: 75%;
    }
  }
}

@media screen and (max-width: $up-media-tablet) {
  .right-side-bar {
    > div { 
      > div:first-child {
        max-width: 100%;
        width: 100%;
      }
      .social-media-aside {
        max-width: 100%!important;
        width: 90%!important;
      }
    }
  }
}

.errorhelp {
  display: flex;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.document-preview {
  padding: 0px; 
  position: relative;

  img {
    display: block; 
    max-width: 144px; 
    max-height: 106px; 
    width: auto; 
    height: auto;
  }

  div[class$='-icon'] {
    width: 50px;
  }
}

.react-pdf__Document {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000001;
  backdrop-filter: blur(8px);
  background-color: rgba(0,0,0,0.3);
}

.react-pdf__Page {
  display: block;
  position: absolute!important;
  max-height: 85vh;
  height: 85vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // border: 1px solid #1f3d2d;
}

.react-pdf__Page__canvas {
  height: 100%!important;
  width: unset!important;
  aspect-ratio: 1 / 1.41; /* ⏹ a perfect square */
}

.react-pdf__Document:hover .page-controls {
  opacity: 1;
}

.react-pdf__Page:hover .download-controls {
  opacity: 1;
}

.page-controls {
  position: absolute;
  bottom: 5%;
  left: 50%;
  background: white;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  border-radius: 4px;
}
.download-controls {
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: white;
  opacity: 0;
  transition: all ease-in-out 0.2s;
  border-radius: 4px;
  z-index: 1000003;
  width: 3em;
  height: 3em;
  display: flex;
  border: 1px solid #1f3d2d;
  justify-content: center;
  align-items: center;
  transform: translate(50%, 50%);
}

.download-controls:hover {
  background-color: #f0f0fa;
  cursor: pointer;
}

.react-pdf__Page .closeButton {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 20px;
  cursor: pointer;
  z-index: 1000005;
  background: white;
  transition: all ease-in-out 0.2s;
  border: 1px solid #1f3d2d;
  border-radius: 4px;
  transform: translate(50%, -50%);
}
.page-controls span {
  font: inherit;
  font-size: 0.8em;
  padding: 0 0.5em;
}
.page-controls button {
  width: 44px;
  height: 44px;
  background: white;
  border: 0;
  font: inherit;
  font-size: 0.8em;
  border-radius: 4px;
}
.page-controls button:enabled:hover {
  cursor: pointer;
}
.page-controls button:enabled:hover,
.page-controls button:enabled:focus {
  background-color: #e6e6e6;
}
.page-controls button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.page-controls button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

[class*="normal_small-image-hero"] {

  .btnConatiner {
    display: none;
  }
}

.logout-btn {
  position: absolute;
  top: -10px;
  right: 8px;
  font-size: 0.7rem;
  font-weight: bold;
}

.session-expire {
  font-size: 0.56rem;
  margin-top: -0.3rem;
  color:rgba(0, 0, 0, 0.3);

  span {
    font-weight: bold;
    color: var(--color-orange);
  }
}

iframe {
  @media screen and (max-width: 992px) {
    min-height: 48vw;
  }
}

.redline {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--color-orange);
  z-index: 1000000;
}